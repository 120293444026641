import React from 'react'
import './Stripes.css'

export function HorizontalStripes() {
    let stripewidth = 1;
    let inputStyle = {
        backgroundImage: `linear-gradient(#ffffff 25%, #000000 25%, #000000 50%, #ffffff 50%, #ffffff 75%, #000000 75%, #000000 100%)`,
        backgroundSize: `${4*stripewidth}px ${4*stripewidth}px`
    };
    return (
        <div className="Stripes" style={inputStyle}></div>
    )
}

export function VerticalStripes() {
    let stripewidth = 1;
    let inputStyle = {
        backgroundImage: `linear-gradient(90deg, #ffffff 25%, #000000 25%, #000000 50%, #ffffff 50%, #ffffff 75%, #000000 75%, #000000 100%)`,
        backgroundSize: `${4*stripewidth}px ${4*stripewidth}px`
    };
    return (
        <div className="Stripes" style={inputStyle}></div>
    )
}