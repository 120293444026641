import './App.css';
import React, { useEffect, useState} from 'react'
import BlackSquare from './components/BlackSquare'
import WhiteSquare from './components/WhiteSquare';
import { HorizontalStripes, VerticalStripes } from './components/Stripes'

function App() {

  const [speed, setSpeed] = useState(1000);
  const [picArray, setPicArray] = useState([BlackSquare, WhiteSquare]);
  const [renderItem, setRenderItem] = useState(BlackSquare);
  const items = [BlackSquare, WhiteSquare, HorizontalStripes, VerticalStripes]
  function handleSpeedChange(e) {
    e.preventDefault();
    setSpeed(e.target.value)
  }

  function handleArrayDelete() {
    setPicArray([])
  }
  function handleAddItem(item) {
    let val = picArray;
    val.push(item);
    setPicArray(val);
  }
  useEffect(() => {
    let arrayIndex = 0;
    const timer = setInterval(() => {
        if (arrayIndex >= picArray.length - 1) {
          arrayIndex = 0;
        }
        else {
          arrayIndex++;
        }
        setRenderItem(picArray[arrayIndex]);
    }, speed);
    return () => {
      window.clearInterval(timer);
    };
  }, [speed, picArray]);

  function LibraryList() {
    return (items.map((item, i) => {
      return (<div onClick={() => handleAddItem(item)} className="InfoPanel" key={i}>{item()}</div>)
    }));
  }

  function ContentList() {
    return (picArray.map((item, i) => {
      return (<div className="InfoPanel" key={i}>{item()}</div>)
    }));
  }


  return (
    <div className="App">
      <div className="Canvas">
        {renderItem}
      </div>


      <div className="ControlPanel">
        <label>Bibliothek:
        <div className="LibraryInfo">
            {<LibraryList />}
          </div>
        </label>
        <label>
          Bildwechselfreq. [ms]:
          <input value={speed} onChange={handleSpeedChange} />
        </label>

        <button name="DeleteArray" onClick={handleArrayDelete}>Bildfolge löschen</button>

        <label>Aktuelle Abfolge:
        <div className="LibraryInfo">
            {<ContentList />}
          </div>
        </label>
      </div>


    </div>
  );
}

export default App;
